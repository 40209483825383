// template-generated
import { ofetch } from 'ofetch';
import { fauxLogger as $logger } from "/usr/src/node-app/node_modules/.pnpm/nuxt-umami@3.0.2_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-umami/dist/runtime/logger";

/**
 * @typedef {import("/usr/src/node-app/node_modules/.pnpm/nuxt-umami@3.0.2_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-umami/dist/types").FetchFn} FetchFn
 * 
 * @typedef {import("/usr/src/node-app/node_modules/.pnpm/nuxt-umami@3.0.2_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-umami/dist/types").BuildPathUrlFn} BuildPathUrlFn
 * 
 * @typedef {import("/usr/src/node-app/node_modules/.pnpm/nuxt-umami@3.0.2_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-umami/dist/types").UmPublicConfig} UmPublicConfig
 */

export const logger = $logger;

/**
 * @type UmPublicConfig
*/
export const config = {
  "trailingSlash": "any",
  "ignoreLocalhost": false,
  "autoTrack": true,
  "useDirective": false,
  "excludeQueryParams": false,
  "enabled": true,
  "domains": null,
  "website": "",
  "endpoint": ""
};

const { endpoint, website, enabled } = config;
let cache = '';

function handleError(err) {
  try {
    const cause = typeof err.data === 'string' ? err.data : err.data.data;
    if (cause && typeof cause === 'string')
      logger('collect', cause);
    else throw new Error('Unknown error');
  }
  catch {
    logger('collect', err);
  }
  return { ok: false };
}

function handleSuccess(response) {
  cache = typeof response === 'string' ? response : '';
  return { ok: true };
}

/**
 * @type BuildPathUrlFn
 */
export function buildPathUrl() {
  const { pathname, search } = new URL(window.location.href);

  const path = pathname;

  return path + search;
}

/**
 * @type FetchFn 
 * 
 * @variation faux
 */
export async function collect(load) {
  const payload = load.payload;

  if (enabled) {
    if (!endpoint)
      logger('endpoint', payload);
    if (!website)
      logger('id', payload);

    return Promise.resolve({ ok: false });
  }
    
  logger('enabled');
  return Promise.resolve({ ok: true });
}
