import revive_payload_client_3xx493nk72 from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_encoding@0.1.13_eslint@8.57.0_ioredis@5._5227mtmjq2rq5yekkunujg3bvi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HQy5PQuHEl from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_encoding@0.1.13_eslint@8.57.0_ioredis@5._5227mtmjq2rq5yekkunujg3bvi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_pU2Kzn11Qp from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_encoding@0.1.13_eslint@8.57.0_ioredis@5._5227mtmjq2rq5yekkunujg3bvi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_gToWSqYsp9 from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_encoding@0.1.13_eslint@8.57.0_ioredis@5._5227mtmjq2rq5yekkunujg3bvi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_KElMovMZgt from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_encoding@0.1.13_eslint@8.57.0_ioredis@5._5227mtmjq2rq5yekkunujg3bvi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_3W715OG9b6 from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_encoding@0.1.13_eslint@8.57.0_ioredis@5._5227mtmjq2rq5yekkunujg3bvi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_iPQdTF7Qa5 from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_encoding@0.1.13_eslint@8.57.0_ioredis@5._5227mtmjq2rq5yekkunujg3bvi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_kdj9nSUToH from "/usr/src/node-app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import plugin_zbacBAXhj0 from "/usr/src/node-app/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/node-app/.nuxt/components.plugin.mjs";
import prefetch_client_U9rkL74vDx from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.6_encoding@0.1.13_eslint@8.57.0_ioredis@5._5227mtmjq2rq5yekkunujg3bvi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_3qcegIYnI9 from "/usr/src/node-app/node_modules/.pnpm/nuxt-umami@3.0.2_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-umami/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/usr/src/node-app/.nuxt/formkitPlugin.mjs";
import plugin_FQUy12CgAC from "/usr/src/node-app/node_modules/.pnpm/@nuxt+icon@1.5.6_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.6_terser@5.36.0__vu_gqkr4dmv2idi22ertaf7x5ask4/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_5THmCYSCuw from "/usr/src/node-app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_SsXXOFiF2K from "/usr/src/node-app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_03k2n14FDf from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/sentry/runtime/plugin.client.ts";
import plugin_7VjdoHA2KM from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/store/runtime/plugin.ts";
import plugin_NcelC5aabP from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/04.error/runtime/plugin.ts";
import conversion_plugin_8TASj4QMaH from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/03.core/runtime/conversion-plugin.ts";
import login_plugin_MUswKxOQBh from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/01.authentication/runtime/login-plugin.ts";
import plugin_XNEuFICceP from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/02.integrations/runtime/plugin.ts";
import _3_user_rQrD34dThm from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/plugins/3.user.ts";
import _5_crisp_client_l67eMSyqKi from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/plugins/5.crisp.client.ts";
export default [
  revive_payload_client_3xx493nk72,
  unhead_HQy5PQuHEl,
  router_pU2Kzn11Qp,
  payload_client_gToWSqYsp9,
  navigation_repaint_client_KElMovMZgt,
  check_outdated_build_client_3W715OG9b6,
  chunk_reload_client_iPQdTF7Qa5,
  plugin_vue3_kdj9nSUToH,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_U9rkL74vDx,
  plugin_3qcegIYnI9,
  formkitPlugin_pZqjah0RUG,
  plugin_FQUy12CgAC,
  switch_locale_path_ssr_5THmCYSCuw,
  i18n_SsXXOFiF2K,
  plugin_client_03k2n14FDf,
  plugin_7VjdoHA2KM,
  plugin_NcelC5aabP,
  conversion_plugin_8TASj4QMaH,
  login_plugin_MUswKxOQBh,
  plugin_XNEuFICceP,
  _3_user_rQrD34dThm,
  _5_crisp_client_l67eMSyqKi
]