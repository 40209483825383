import { default as deletedliftmoucYBMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/deleted.vue?macro=true";
import { default as privacyQO1YT8klMZMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/en/privacy.vue?macro=true";
import { default as tosD5xWX68FfOMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/en/tos.vue?macro=true";
import { default as imprintTZoE95licoMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/imprint.vue?macro=true";
import { default as indexE8D5p857zYMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/index.vue?macro=true";
import { default as privacyI57hwYZ4cgMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/privacy.vue?macro=true";
import { default as settingsZAGcd77kK1Meta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/settings.vue?macro=true";
import { default as smatching_45demostwOfjcEEJMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/smatching-demo.vue?macro=true";
import { default as tosQuVaJskIeNMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/tos.vue?macro=true";
import { default as loginODTdjdU81UMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/01.authentication/pages/login.vue?macro=true";
import { default as sign_45upNbJUAcF9CqMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/01.authentication/pages/sign-up.vue?macro=true";
import { default as forgot_45passwordOTtOhPofgSMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/01.authentication/pages/forgot-password.vue?macro=true";
import { default as indexvnWfL56Lr4Meta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/03.core/pages/profile/index.vue?macro=true";
import { default as inviteLJut45ek27Meta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/03.core/pages/profile/invite.vue?macro=true";
import { default as smatching63QPbrj1P5Meta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/03.core/pages/smatching.vue?macro=true";
import { default as helpDBIsKAMvJlMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/03.core/pages/help.vue?macro=true";
export default [
  {
    name: "deleted",
    path: "/deleted",
    meta: deletedliftmoucYBMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/deleted.vue")
  },
  {
    name: "en-privacy",
    path: "/en/privacy",
    meta: privacyQO1YT8klMZMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/en/privacy.vue")
  },
  {
    name: "en-tos",
    path: "/en/tos",
    meta: tosD5xWX68FfOMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/en/tos.vue")
  },
  {
    name: "imprint",
    path: "/imprint",
    meta: imprintTZoE95licoMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/imprint.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyI57hwYZ4cgMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/privacy.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/settings.vue")
  },
  {
    name: "smatching-demo",
    path: "/smatching-demo",
    meta: smatching_45demostwOfjcEEJMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/smatching-demo.vue")
  },
  {
    name: "tos",
    path: "/tos",
    meta: tosQuVaJskIeNMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/pages/tos.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginODTdjdU81UMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/01.authentication/pages/login.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upNbJUAcF9CqMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/01.authentication/pages/sign-up.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordOTtOhPofgSMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/01.authentication/pages/forgot-password.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/03.core/pages/profile/index.vue")
  },
  {
    name: "profile-invite",
    path: "/profile/invite",
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/03.core/pages/profile/invite.vue")
  },
  {
    name: "smatching",
    path: "/smatching",
    meta: smatching63QPbrj1P5Meta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/03.core/pages/smatching.vue")
  },
  {
    name: "help",
    path: "/help",
    meta: helpDBIsKAMvJlMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/modules/03.core/pages/help.vue")
  }
]