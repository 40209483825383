
// @ts-nocheck
import locale__usr_src_node_app_node_modules__pnpm__64smatched_43nuxt_core_641_12_2__64formkit_43core_641_6_7__64formkit_43inputs_641_6_7_encoding_640_1_13_esbuild_64_hnogsctdtqxo2s6b4bhv6kpovi_node_modules__64smatched_nuxt_core_config_locales_en_js from "../node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/config/locales/en.js";
import locale__usr_src_node_app_node_modules__pnpm__64smatched_43nuxt_core_641_12_2__64formkit_43core_641_6_7__64formkit_43inputs_641_6_7_encoding_640_1_13_esbuild_64_hnogsctdtqxo2s6b4bhv6kpovi_node_modules__64smatched_nuxt_core_config_locales_de_js from "../node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/config/locales/de.js";


export const localeCodes =  [
  "en",
  "de"
]

export const localeLoaders = {
  "en": [{ key: "../node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/config/locales/en.js", load: () => Promise.resolve(locale__usr_src_node_app_node_modules__pnpm__64smatched_43nuxt_core_641_12_2__64formkit_43core_641_6_7__64formkit_43inputs_641_6_7_encoding_640_1_13_esbuild_64_hnogsctdtqxo2s6b4bhv6kpovi_node_modules__64smatched_nuxt_core_config_locales_en_js), cache: true }],
  "de": [{ key: "../node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/config/locales/de.js", load: () => Promise.resolve(locale__usr_src_node_app_node_modules__pnpm__64smatched_43nuxt_core_641_12_2__64formkit_43core_641_6_7__64formkit_43inputs_641_6_7_encoding_640_1_13_esbuild_64_hnogsctdtqxo2s6b4bhv6kpovi_node_modules__64smatched_nuxt_core_config_locales_de_js), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "files": [
        "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/config/locales/en.js"
      ]
    },
    {
      "code": "de",
      "name": "Deutsch",
      "files": [
        "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/config/locales/de.js"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "./config/locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/config/locales/en.js"
      }
    ]
  },
  {
    "code": "de",
    "name": "Deutsch",
    "files": [
      {
        "path": "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.12.2_@formkit+core@1.6.7_@formkit+inputs@1.6.7_encoding@0.1.13_esbuild@_hnogsctdtqxo2s6b4bhv6kpovi/node_modules/@smatched/nuxt-core/config/locales/de.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
